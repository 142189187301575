import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { DateTime } from "luxon"
import slugify from "slugify"
import Button from "../buttons/button"
import ChevronToggle from "../page-layout/ui/chevron-toggle"
import PillToggle from "../page-layout/ui/pill-toggle"
import DataProvider from "../utils/DataProvider"
import swatDatesUtil from "../utils/swatDatesUtil"
import RangedTimeElement from "../utils/RangedTimeElement"
import { CalendarModalContext } from "../calendar-modal/calendar-modal"
import Skeleton from "../page-layout/ui/skeleton"
import { HoursKey, getHoursSymbol } from "./keys-legends/hours-key"
import DietaryKey from "./keys-legends/dietary-key"
import { MenusContext } from "../utils/MenusContext"
import { MenuContent } from "../utils/dietaryLabelsUtil"

export const MenuFeeds = () => {
  const data = useStaticQuery(graphql`
    query MenusCollection {
      allNodeDiningMenus {
        edges {
          node {
            id
            drupal_id
            title
            field_display_upcoming_menu
            field_dining_location_labels
            relationships {
              field_dining_menu_feeds {
                ... on paragraph__cbord_feed {
                  id
                  field_cbord_location
                }
                ... on paragraph__calendar_feed {
                  id
                  relationships {
                    field_google_calendar {
                      field_calendar_id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <HoursKey />
      {data.allNodeDiningMenus.edges.map(({ node }, index) => {
        return <MenusFeedItems {...node} key={index} />
      })}
    </>
  )
}

const MenusFeedItems = props => {
  const queryName = props.relationships.field_dining_menu_feeds.relationships
    ?.field_google_calendar
    ? "googlecalfeed"
    : "cbordnetmenufeed"
  const calendarTitle = props.relationships.field_dining_menu_feeds
    .relationships?.field_google_calendar
    ? props.relationships.field_dining_menu_feeds.relationships.field_google_calendar.title.replace(
        /(\[Dash\] )/g,
        ""
      )
    : props.title
  const showUpcomingMenu = props.field_display_upcoming_menu
  const locationLabels = props.field_dining_location_labels
  const timeWindow = "1 day"
  const queryVariables = {
    calendarId: props.relationships.field_dining_menu_feeds.relationships
      ?.field_google_calendar
      ? props.relationships.field_dining_menu_feeds.relationships
          .field_google_calendar.field_calendar_id
      : props.relationships.field_dining_menu_feeds?.field_cbord_location,
    order: "ASC",
    timeWindow: timeWindow,
    // editAccess: paragraphData.editAccess,
  }

  return (
    <div className="mt-4">
      <DataProvider
        queryName={queryName}
        queryVariables={queryVariables}
        key={calendarTitle}
      >
        <MenusFeedItem
          calendarId={queryVariables.calendarId}
          calendarTitle={calendarTitle}
          showUpcomingMenu={showUpcomingMenu}
          locationLabels={locationLabels}
          {...props}
        />
      </DataProvider>
    </div>
  )
}

const MenusFeedItem = props => {
  const {
    error,
    loading,
    data,
    calendarTitle,
    calendarId,
    showUpcomingMenu,
    locationLabels,
  } = props
  const [currentMenu, setCurrentMenu] = useState(0)
  const [diningLocationToggle, setDiningLocationToggle] = useState(false)
  const diningLocationVisibility = !diningLocationToggle ? ["tw-sr-only"] : []
  const [calendarModalState, calendarModalDispatch] = React.useContext(
    CalendarModalContext
  )
  const currentDate = DateTime.now().toLocal()
  let openStatus
  let friendlyOpenStatus = ""

  useEffect(() => {
    if (data !== undefined) {
      setDiningLocationToggle(prevState => {
        return data.result.data.length >= 1
      })
    }
  }, [data])

  if (!loading && !error) {
    if (data.result.data.length >= 1) {
      const menus = data.result.data.filter((menu, i) => {
        return menu.status !== "past"
      })
      const menuTabs = menus.map((menu, i) => {
        const { title, id, startdate, enddate } = menu
        const menuPosition = i
        const currentTime = DateTime.local()
        const hours = {
          openTime: DateTime.fromISO(startdate),
          closeTime: DateTime.fromISO(enddate),
        }
        return (
          <PillToggle
            isActive={currentMenu === menuPosition}
            onClick={e => setCurrentMenu(menuPosition)}
            additionalAttr={{
              "data-menu-id": id,
              "data-type": "toggle",
              "data-context": calendarTitle.toLowerCase() + " menus",
              "data-action": "select meal",
            }}
            key={id}
          >
            {title +
              (currentTime.hasSame(hours.openTime, "day")
                ? ""
                : hours.openTime.diff(currentTime, "days").days < 0 &&
                  hours.openTime.diff(currentTime, "days").days >= -1
                ? " (yesterday)"
                : " (" +
                  swatDatesUtil(hours.openTime.toFormat("MMM'.' d")) +
                  ")")}
          </PillToggle>
        )
      })
      const upcomingMenuHours = menus.map(calEvents => {
        return calEvents.status
      })
      if (upcomingMenuHours.includes("open")) {
        const statusData = menus.find(({ status }) => status === "open")
        const startDate = DateTime.fromISO(statusData.startdate)
        const endDate = DateTime.fromISO(statusData.enddate)
        openStatus = "open"
        friendlyOpenStatus = (
          <>
            {calendarTitle.toLowerCase() === "sharples"
              ? `Open for ${statusData.title} `
              : `Open `}
            <RangedTimeElement start={startDate} end={endDate} />
          </>
        )
      } else if (upcomingMenuHours.includes("closing")) {
        const statusData = menus.find(({ status }) => status === "closing")
        openStatus = "closing"
        friendlyOpenStatus = (
          <>
            {calendarTitle.toLowerCase() === "sharples"
              ? `Open, ${statusData.title} ends at `
              : `Open, closes at `}
            <time dateTime={statusData.enddate}>
              {swatDatesUtil(
                DateTime.fromISO(statusData.enddate)
                  .toLocal()
                  .toFormat("h:mm a")
              )}
            </time>
          </>
        )
      } else if (
        upcomingMenuHours.includes("closed") ||
        upcomingMenuHours.includes("future")
      ) {
        const statusData = menus.find(({ status }) => status === "future")
        openStatus = "closed"
        if (statusData !== undefined) {
          friendlyOpenStatus = (
            <>
              {calendarTitle.toLowerCase() === "sharples"
                ? `Closed, opens for ${statusData.title} `
                : `Closed, opens `}
              <time dateTime={statusData.startdate}>
                {currentDate.day === DateTime.fromISO(statusData.startdate).day
                  ? "today"
                  : DateTime.fromISO(statusData.startdate).weekdayLong}{" "}
                at{" "}
                {swatDatesUtil(
                  DateTime.fromISO(statusData.startdate)
                    .toLocal()
                    .toFormat("h:mm a")
                )}
              </time>
            </>
          )
        } else {
          friendlyOpenStatus = "Closed"
        }
      }

      const processedMenus = menus.map((calEvents, i) => {
        const menuWrapperClasses =
          currentMenu === i
            ? ["dining-location--wrapper"]
            : ["dining-location--wrapper", "tw-sr-only"]

        return (
          <div className={menuWrapperClasses.join(" ")} key={i}>
            <MenusMealDisplay event={calEvents} />

            {/* The FullCalendar buttons */}
            <div className="lg:mt-4">
              {showUpcomingMenu ? (
                <Button
                  color="primary"
                  icon="calendar"
                  additionalAttr={{
                    "data-context": `${calendarTitle.toLowerCase()} menu listing`,
                    "data-action": "view upcoming calendar",
                    rel: "noreferrer",
                  }}
                  additionalClasses={["w-full", "my-3"]}
                  url={`menu/${slugify(calendarTitle, {
                    lower: true,
                    strict: true,
                  })}`}
                >
                  View Upcoming Menu
                </Button>
              ) : null}
              {/* only show edit button if user has edit access */}
              {calEvents.editurl ? (
                <>
                  <Button
                    color="dash-blue"
                    icon="pencil"
                    additionalAttr={{
                      "data-context": `${calendarTitle.toLowerCase()} menu listing`,
                      "data-action": "edit menu event",
                      target: "_blank",
                      rel: "noreferrer",
                    }}
                    additionalClasses={["w-full", "mb-3"]}
                    onClick={() => window.open(calEvents.editurl)}
                  >
                    Edit {calEvents.title}
                  </Button>
                  <Button
                    color="dash-blue"
                    icon="pencil"
                    additionalAttr={{
                      "data-context": `${calendarTitle.toLowerCase()} menu listing`,
                      "data-action": "edit menu calendar",
                      target: "_blank",
                      rel: "noreferrer",
                    }}
                    additionalClasses={["w-full", "mb-3"]}
                    onClick={() =>
                      calendarModalDispatch({
                        calendarId: calendarId,
                        template: "menu",
                      })
                    }
                  >
                    Edit Calendar
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        )
      })

      return (
        <LocationWrapper
          {...props}
          openStatus={openStatus}
          friendlyOpenStatus={friendlyOpenStatus}
          hasMenus={true}
          locationLabels={locationLabels}
          calendarTitle={calendarTitle}
          diningLocationToggle={diningLocationToggle}
          diningLocationVisibility={diningLocationVisibility}
          setDiningLocationToggle={setDiningLocationToggle}
        >
          <div className="my-4 flex flex-row flex-wrap gap-3">{menuTabs}</div>
          {processedMenus}
        </LocationWrapper>
      )
    } else if (showUpcomingMenu) {
      return (
        <LocationWrapper
          {...props}
          openStatus="closed"
          friendlyOpenStatus={"Closed for the day."}
          hasMenus={false}
          calendarTitle={calendarTitle}
          diningLocationToggle={diningLocationToggle}
          diningLocationVisibility={["dining-location--wrapper"]}
          setDiningLocationToggle={setDiningLocationToggle}
        >
          <div>
            <Button
              color="primary"
              icon="calendar"
              additionalAttr={{
                "data-context": `${calendarTitle.toLowerCase()} menu listing`,
                "data-action": "view upcoming calendar",
                rel: "noreferrer",
              }}
              additionalClasses={["w-auto", "px-4"]}
              url={`menu/${slugify(calendarTitle, {
                lower: true,
                strict: true,
              })}`}
            >
              View Upcoming Menu
            </Button>
          </div>
        </LocationWrapper>
      )
    } else {
      return (
        <LocationWrapper
          {...props}
          openStatus="closed"
          friendlyOpenStatus={"Closed for the day."}
          hasMenus={false}
          locationLabels={locationLabels}
          calendarTitle={calendarTitle}
          diningLocationToggle={diningLocationToggle}
          diningLocationVisibility={diningLocationVisibility}
          setDiningLocationToggle={setDiningLocationToggle}
        />
      )
    }
  } else {
    return <Skeleton />
  }
}

/**
 * Outputs the menu (meal) along with the associated dietary labels
 */
export const MenusMealDisplay = props => {
  const { event } = props
  const { title, startdate, enddate, allday, description } = event
  const [menusState, menusDispatch] = React.useContext(MenusContext)
  const { toggledLabels } = menusState
  const menuClasses = ["prose", "dark:prose-dark", "text-black", "p-5"]
  const startTime = DateTime.fromISO(startdate)
  const endTime = DateTime.fromISO(enddate)

  return (
    <>
      {/* The hours for the currently displayed menu */}
      <div className="flex flex-col gap-3">
        {allday !== true ? (
          <div className="inline-block font-bold">
            <span className="tw-sr-only">{title} Hours:</span>
            <span>
              <RangedTimeElement start={startTime} end={endTime} />
            </span>
          </div>
        ) : (
          <div className="inline-block font-bold">
            <span className="tw-sr-only">All Day</span>
          </div>
        )}

        {/* The generic dietary labels legend */}
        <DietaryKey
          describedText={description}
          filterProps={{ isUpcomingMenu: false }}
        />
      </div>

      {/* The menu content with processed dietary labels */}
      <div className="dining-location--menu rounded-md bg-gray-100 dark:bg-gray-800">
        <div className={menuClasses.join(" ")}>
          <MenuContent
            description={description}
            toggledLabels={toggledLabels}
          />
        </div>
      </div>
    </>
  )
}

const LocationWrapper = ({
  openStatus,
  hasMenus,
  friendlyOpenStatus,
  locationLabels,
  calendarTitle,
  diningLocationToggle,
  diningLocationVisibility,
  setDiningLocationToggle,
  children,
}) => {
  return (
    <div className="my-3" key={calendarTitle}>
      <h2 className="text-xl font-bold">
        <button
          data-type="toggle"
          data-context="dining location"
          data-action={diningLocationToggle ? "collapse" : "expand"}
          data-label={calendarTitle}
          className="text-left"
          type="button"
          onClick={() => setDiningLocationToggle(!diningLocationToggle)}
          disabled={!hasMenus}
        >
          <span className="pointer-events-none">
            <span className="break-words font-bold">{calendarTitle}</span>
            <span
              className={
                "ml-1 inline-block text-base " +
                "hours-label--" +
                (openStatus ? openStatus : "other")
              }
            >
              {getHoursSymbol(openStatus)}
            </span>
            {hasMenus && (
              <span className="text-base">
                <ChevronToggle
                  chevronClasses={["inline-block", "ml-2"]}
                  toggle={diningLocationToggle}
                />
              </span>
            )}
            <br />
            <span className="text-sm">{friendlyOpenStatus}</span>
            <br />
            {locationLabels && locationLabels.length > 0
              ? locationLabels.map((label, index) => {
                  let formattedLabel

                  switch (label) {
                    case "student_run":
                      formattedLabel = "Student-run"
                      break
                    default:
                      formattedLabel = label
                        .split("_")
                        .map(
                          word => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                      break
                  }

                  return (
                    <span
                      key={index}
                      className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
                    >
                      {formattedLabel}
                    </span>
                  )
                })
              : ""}
          </span>
        </button>
      </h2>
      <div className={diningLocationVisibility.join(" ")}>{children}</div>
    </div>
  )
}
